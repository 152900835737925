@import "src/variables";

.outerContainer {
  width: 100%;
  display: flex;
  border-radius: 5px;
  overflow: hidden;

  button {
    outline: none;
    border: none;
    min-width: 100%;


    &:focus,
    &:hover,
    &:focus-visible {
      outline: none;
      border: none;
    }
  }

  :global {
    .swiper {
      width: 100vw;
      min-height: 100px;
    }
    .swiper-slide{
      display: flex;
      align-items: center;
      justify-content: center;
      ::selection {
        background: transparent;
      }
      img{
        width: 35px;
        height: 35px;
        opacity: 0.4;
        transition: 1s;
        ::selection {
          background: transparent;
        }
      }
    }
    .swiper-slide-next, .swiper-slide-prev{
      img{
        width: 50px;
        height: 50px;
        opacity: 0.6;
        ::selection {
          background: transparent;
        }
      }
    }
    .swiper-slide-active{
      img{
        width: 80px;
        height: 80px;
        opacity: 1;
      }
    }

  }
}

.outerContainer > div {
}

.outerContainer .carouselWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: $smallDisplay) {
    width: 100%;
  }
}

.carouselButton {
  cursor: pointer;
  display: flex;
  max-width: 50px;
  border-radius: 30px;
  height: 26px;
  background-color: transparent;
  outline: none;
  border: none;
  justify-content: center;
  position: relative;
  z-index: 2;

  svg {
    height: 26px;
    width: 26px;
  }
}

.carousel {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--white);
  @media only screen and (max-width: $smallDisplay) {
    flex-direction: column;
  }
}

.slides {
  align-self: flex-start;
  width: 40%;
  overflow: hidden;
  min-width: 40%;
  @media only screen and (max-width: $smallDisplay) {
    width: 100%;
    min-width: 100%;
  }
}

.carouselInner {
  position: relative;
  max-height: 91vh;
  height: 70vh;
  display: flex;
  justify-content: center;
  //margin-top: 10rem;
  @media only screen and (max-width: $smallDisplay) {
    max-height: unset;
    height: 420px;
  }
  @media only screen and (max-width: $extraSmallDisplay) {
    height: 43vh;
  }
}

.carouselInner::before,
.carouselInner::after {
  content: "";
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 110px;
  pointer-events: none;
}

.carouselInner::before {
  top: 0;
  //background: linear-gradient(0deg, hsla(0, 0%, 100%, 0) 0%, hsla(27, 100%, 48%, 1) 100%);
}

.carouselInner::after {
  bottom: 0;
  //background: linear-gradient(0deg, hsla(27, 100%, 48%, 1) 0%, hsla(0, 0%, 100%, 0) 100%);
}

.carouselItem {
  width: 100%;
  position: absolute;
  background: none;
  border: none;
  padding: 0;
  margin: 18vh 0 0;
  top: 100px;
  transition: transform 0.3s ease, opacity 0.3s ease, width 0.3s ease;
  opacity: 0;
  pointer-events: none;
  filter: brightness(999);

  img {
    transition: zoom 2s ease;
    width: 100px;
    height: 100px;
  }

  @media only screen and (max-width: $smallDisplay) {
    margin: 8vh 0 0;
  }
  @media only screen and (max-width: $extraSmallDisplay) {
    margin: 5vh 0 0;
  }
}

.visible {
  pointer-events: auto;
  opacity: 0.7;

  .logo {
    zoom: 0.7;
  }
}

.lighter {
  pointer-events: auto;
  opacity: 0.5 !important;

  .logo {
    zoom: 0.4;
  }
}

.lightest {
  pointer-events: auto;
  //opacity: 0.2 !important;
  opacity: 0 !important;

  .logo {
    zoom: 0.1;
  }
}

.active {
  pointer-events: auto;
  filter: none;
  opacity: 1 !important;

  .logo {
    zoom: 1.2;
  }
}

.logo {
  cursor: pointer;
  max-width: 50%;
  @media only screen and (max-width: $smallDisplay) {
    max-width: unset;
  }
}

.short {
  max-width: 40%;
}


.textTitle {
  display: flex;
  align-items: center;
  font-size: 32px;
  margin-bottom: 35px;
  @media only screen and (max-width: $mediumDisplay) {
    font-size: 16px;
    margin-bottom: 0px;
  }
}

.titleLogo {
  margin-right: 20px;
  @media only screen and (max-width: $mediumDisplay) {
    svg {
      width: 40px;
    }
  }
}

.line {
  display: flex;
  margin-left: 20px;

  div {
    background: #D83634;
    height: 5px;

    &:nth-child(1) {
      width: 8px;
      margin-right: 4px;
      transform: skewX(-30deg);
    }

    &:nth-child(2) {
      width: 8px;
      margin-right: 4px;
      transform: skewX(-30deg);
    }

    &:nth-child(3) {
      width: 200px;
      transform: skewX(-30deg);
      @media only screen and (max-width: $smallDisplay) {
        width: 100px;
      }
    }
  }
}

.content {
  height: 100%;
  width: 60%;
  padding: 130px 0 0;
  @media only screen and (max-width: $mediumDisplay) {
    padding: 100px 0 80px;
  }
  @media only screen and (max-width: $smallDisplay) {
    padding: 100px 0 80px;
  }
  @media only screen and (max-width: $smallDisplay) {
    width: 100%;
    height: auto;
    padding: 0 30px;
  }
}

.contentInner {
  padding-right: 100px;
  @media only screen and (max-width: $smallDisplay) {
    padding-right: 0
  }
}

.text {
  font-size: 20px;
  font-family: Arial;
  line-height: 1.5;
  @media only screen and (max-width: $mediumDisplay) {
    font-size: 16px;
  }
  @media only screen and (max-width: $smallDisplay) {
    font-size: 12px;
  }
}

.website {
  display: flex;
  align-items: center;
  font-size: 25px;
  margin: 30px 0 100px;
  @media only screen and (max-width: $smallDisplay) {
    font-size: 12px;
    margin: 30px 0 50px;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  a {
    margin-left: 20px;
    color: white;
    letter-spacing: 1px;

    &:visited, &:hover, &:focus-within, &:focus {
      color: white;
    }
  }

}

.photo {
  width: 100%;
}

.partner{
  text-align: center;
  img{
    width: 60px;
    height: 60px;
  }
}