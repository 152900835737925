@import "src/variables";
.cta {
  cursor: pointer;
  position: relative;
  min-height: 80px;
  width: 360px;
  overflow: hidden;
  display: flex;
  align-items: center;
  @media only screen and (max-width: $largeDisplay) {
    max-height: 65px;
    min-height: 65px;
    width: 315px;
  }
  @media only screen and (max-width: $smallDisplay) {
    width: 280px;
  }
  &:after {
    content: "";
    width: 107px;
    height: 3px;
    background: white;
    position: absolute;
    top: 40px;
    right: 10px;
    @media only screen and (max-width: $largeDisplay) {
      width: 90px;
      top: 30px;
      right: 0px;
    }
  }
}

.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 325px;
  height: 100%;
  background-color: #D83634;
  transform: skewX(-40deg);
  transform-origin: top right;
  @media only screen and (max-width: $largeDisplay) {
    width: 280px;
  }
  @media only screen and (max-width: $smallDisplay) {
    width: 260px;
  }

}

.text {
  position: absolute;
  padding: 0 30px;
  font-size: 20px;
  @media only screen and (max-width: $largeDisplay) {
    font-size: 16px;
  }
  @media only screen and (max-width: $smallDisplay) {
    font-size: 14px;
  }
}
.mirror{
  .bg{
    left: 88px;
  }
  .text{
    padding-left: 110px;
    padding-right: 0;
  }
  &:after{
    left: -10px;
  }
  @media only screen and (max-width: $smallDisplay) {
    width: 280px;
  }

}