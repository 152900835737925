.logo {
  position: absolute;
  top: 70px;
  right: 90px;
  width: 250px;

  svg {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    right: 0;
    text-align: center;
    svg {
      width: 200px;
    }
  }
}

.videoContainer {
  width: 100%; /* Adjust the width of the container as needed */
  height: 100vh; /* Adjust the height of the container as needed */
  background: black;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  ////
  object-position: center -80px;
  background: black;
  @media screen and (max-width: 768px) {
    height: 65%;
    object-position: center;
    background: black;
    margin-top: 10vh;
  }
}

.comingsoonBlock {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 7%;
  z-index: 999;
  color: white;
  left: 0;
  right: 0;
  @media screen and (max-width: 768px) {
    bottom: 60px;
  }
}

.comingSoon {
  font-size: 65px;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    font-size: 45px;
  }
}

.timeLeft {
  font-size: 18px;
  color: #b3b3b3;
  padding: 0;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    padding: 15px 0 10px;
  }
}

.countdown {
  display: flex;
  justify-content: center;
}

.block {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    width: 70px;
    font-size: 14px;
  }
}

.number {
  font-size: 49px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
}

.socials{
  margin-top: 20px;
  a {
    margin-right: 20px;
    margin-top: 20px;
    display: inline-block;
  }

  svg {
    width: 50px;
    height: 50px;
  }
}