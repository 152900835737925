@font-face {
    font-family: Jaldi;
    src: url("fonts/Jaldi-Regular.ttf") format("truetype");
}

@font-face {
    font-family: JaldiBold;
    src: url("fonts/Jaldi-Bold.ttf") format("truetype");
}

@font-face {
    font-family: ArmguardU;
    src: url("fonts/ArmguardU.ttf") format("truetype");
}
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #272727;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #4E4F50;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #3E3F3F;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: ArmguardU, Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: ArmguardU, Arial, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

