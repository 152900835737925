@import "src/variables";

.root {
  background: linear-gradient(#111010, #262626);
  color: white;
  padding: 120px 140px 50px 0;

  font-family: ArmguardU, Arial, serif;
  @media only screen and (max-width: $mediumDisplay) {
    padding: 80px 0 0
  }
  @media only screen and (max-width: $smallDisplay) {
    padding: 60px 0 0
  }
}

.title {
  font-size: 74px;
  letter-spacing: 1px;
  margin-left: 140px;
  margin-bottom: 40px;
  @media only screen and (max-width: $mediumDisplay) {
    padding: 0 30px 0;
    margin-left: 0;
    font-size: 54px;
    margin-bottom: 0px;
  }
  @media only screen and (max-width: $smallDisplay) {
    padding: 0 30px 0;
    margin-left: 0;
    font-size: 35px;
  }

  span {
    color: #D83634;
  }
}

.button {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 70px;
  margin-top: 40px;
  @media only screen and (max-width: $mediumDisplay) {
    justify-content: flex-start;
    padding-left: 30px;
  }
}

.content {
  display: flex;
  @media only screen and (max-width: $mediumDisplay) {
    flex-direction: column-reverse;
  }
}

.videoContainer {
  flex: 1;
  width: 60%;
  margin-right: 50px;
  @media only screen and (max-width: $mediumDisplay) {
    width: 100%;
    margin-right: 0px;
  }
}

.textButton {
  width: 40%;
  @media only screen and (max-width: $mediumDisplay) {
    width: 100%;
  }
}

.video {
  width: 100%;
  object-fit: contain;
  object-position: center;
  background: black;
}

.textWrapper {
  flex: 1;

  @media only screen and (max-width: $mediumDisplay) {
    padding: 50px 30px
  }
  @media only screen and (max-width: $smallDisplay) {
    padding: 50px 30px 0;
  }
}

.text {
  font-family: Arial;
}


.textTitle {
  display: flex;
  align-items: center;
  font-size: 32px;
  margin-bottom: 35px;
  @media only screen and (max-width: $mediumDisplay) {
    font-size: 16px;
    margin-bottom: 0px;
  }
}

.logo {
  margin-right: 20px;
  @media only screen and (max-width: $mediumDisplay) {
    svg {
      width: 40px;
    }
  }
}

.line {
  display: flex;
  margin-left: 20px;

  div {
    background: white;
    height: 5px;

    &:nth-child(1) {
      width: 8px;
      margin-right: 4px;
      transform: skewX(-30deg);
    }

    &:nth-child(2) {
      width: 8px;
      margin-right: 4px;
      transform: skewX(-30deg);
    }

    &:nth-child(3) {
      width: 200px;
      transform: skewX(-30deg);
      @media only screen and (max-width: $smallDisplay) {
        width: 100px;
      }
    }
  }
}

.redLine {
  display: flex;
  margin-left: 20px;
  justify-content: end;
  margin-top: -10px;

  div {
    background: #D83634;
    height: 10px;

    &:nth-child(1) {
      width: 10px;
      margin-right: 4px;
      transform: skewX(-36deg);
    }

    &:nth-child(2) {
      width: 10px;
      margin-right: 1px;
      transform: skewX(-36deg);
    }

    &:nth-child(3) {
      width: 50%;
      overflow: hidden;
      background: transparent;

      div {
        width: 100%;
        transform: skewX(-36deg) translate(3px, 0px);
        background: #D83634;
      }
    }
  }
}



