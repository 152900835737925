@import "src/variables";
.root {
  position: relative;
  background: #1A1A1A;
  padding: 80px 0 0;
  color: white;
  width: 100%;
  @media only screen and (max-width: $mediumDisplay) {
    padding: 40px 0 80px;
  }
  @media only screen and (max-width: $smallDisplay) {
    padding: 20px 0 80px;
  }
}

.title {
  position: absolute;
  top: 80px;
  font-size: 74px;
  letter-spacing: 1px;
  padding-bottom: 40px;
  left: 40%;
  @media only screen and (max-width: $mediumDisplay) {
    font-size: 54px;
    top: 45px;
  }
  @media only screen and (max-width: $smallDisplay) {
    left: 0;
    margin-left: 0;
    font-size: 35px;
    position: relative;
    padding: 0;
    top: 0;
    text-align: center;
    margin-bottom: 40px;
  }
  span {
    color: #D83634;
  }
}
.partner{
  img{
    width: 80px;
    height: 80px;
  }
}