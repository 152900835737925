@import "src/variables";

.root {
  background: linear-gradient(#262626, #111010);
  color: white;
  padding: 100px 0 40px 140px;
  min-height: 100vh;
  font-family: ArmguardU, Arial, serif;

  :global {
    .swiper-pagination-bullet {
      width: 42px;
      height: 3px;
      border-radius: 0;
      background: #D9D9D9;
    }

    .swiper-pagination-bullet-active {
      background: #D83634;
    }

    .swiper-slide-prev, .swiper-slide-next {
      & > div {
        zoom: 0.7;
        width: 80%;
        margin: 10% auto 0;
      }
    }
  }

  @media only screen and (max-width: $mediumDisplay) {
    padding: 80px 0 0 80px
  }
  @media only screen and (max-width: $smallDisplay) {
    padding: 80px 0 0 0;
    position: relative;
  }
}

.title {
  font-size: 74px;
  letter-spacing: 1px;
  text-align: center;
  padding-bottom: 80px;

  span {
    color: #D83634;
  }

  @media only screen and (max-width: $mediumDisplay) {
    margin-left: 0;
    font-size: 54px;
    padding-bottom: 40px;
  }
  @media only screen and (max-width: $smallDisplay) {
    margin-left: 0;
    font-size: 35px;
    padding-bottom: 20px;
    position: absolute;
    text-align: center;
    width: 100%;
  }

}

.content {
  display: flex;
  @media only screen and (max-width: $smallDisplay) {
    flex-direction: column;
    padding: 80px 0 0 30px;
  }
}

.imgContainer {
  flex: 1;
  width: 50%;
  @media only screen and (max-width: $smallDisplay) {
    width: 100%;
    margin-top: 30px;
  }

}

.img {
  width: 100%;
  object-fit: contain;
  object-position: center;
  background: black;
  margin-top: 115px;
  @media only screen and (max-width: $mediumDisplay) {
    margin-top: 65px;
  }
  @media only screen and (max-width: $smallDisplay) {
    margin-top: 0px;
  }
}

.time {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  opacity: 0.6;
  color: #fff;

  svg {
    fill: #fff;
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
}

.text {
  flex: 1;
  margin-right: 100px;
  @media only screen and (max-width: $mediumDisplay) {
    margin-right: 30px;
  }
}

.button {
  margin-bottom: 40px;

  span {
    width: 50%;
  }
}

.desc {
  line-height: 1.5;
  font-family: Arial;
  margin-bottom: 40px;
  font-size: 18px;
  @media only screen and (max-width: $mediumDisplay) {
    font-size: 16px;
  }
  @media only screen and (max-width: $smallDisplay) {
    font-size: 12px;
  }
}

.textTitle {
  display: flex;
  align-items: center;
  font-size: 32px;
  margin-bottom: 35px;
  @media only screen and (max-width: $mediumDisplay) {
    font-size: 16px;
    margin-bottom: 0px;

  }
}

.logo {
  margin-right: 20px;
  @media only screen and (max-width: $mediumDisplay) {
    svg {
      width: 40px;
    }
  }
}

.line {
  display: flex;
  margin-left: 20px;

  div {
    background: #D83634;
    height: 5px;

    &:nth-child(1) {
      width: 8px;
      margin-right: 4px;
      transform: skewX(-30deg);
    }

    &:nth-child(2) {
      width: 8px;
      margin-right: 4px;
      transform: skewX(-30deg);
    }

    &:nth-child(3) {
      width: 200px;
      transform: skewX(-30deg);
      @media only screen and (max-width: $smallDisplay) {
        width: 100px;
      }
    }
  }
}


.navigation {
  display: flex;
  //margin-top: 50px;
  padding-bottom: 50px;
  @media only screen and (max-width: $smallDisplay) {
    position: absolute;
    top: 0;
    left: 30px;
    margin-top: 0;
    padding: 0;
    justify-content: space-between;
    width: calc(100% - 30px);
    z-index: 5;
  }

  & > div {
    margin-right: 40px;
  }
}