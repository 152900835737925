@import "src/variables";
.modal{
  background: #1C1C1C;
  font-family: Arial;
  padding: 30px 60px 0;
  @media only screen and (max-width: $smallDisplay) {
    padding: 70px 10px 0 30px
  }
}
.successModal{
  padding: 30px;
  text-align: center;
  @media only screen and (max-width: $smallDisplay) {
    height: 95vh;
    padding: 0;
    display: flex;
    align-items: center;
  }
  .close{
    top: 30px;
  }
}
.success{
  padding-top: 40px;
  font-family: Arial;
  line-height: 1.5;
}
.title{
  text-align: center;
  margin: 10px 0 30px;
  font-family: ArmguardU;
  letter-spacing: 1px;
  font-size: 24px;
  @media only screen and (max-width: $smallDisplay) {
    font-size: 20px;
  }
}
.row{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 15px;
  @media only screen and (max-width: $smallDisplay) {
    grid-template-columns: 1fr;
  }
}
.close{
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  right: 40px;
  top: 60px;
  cursor: pointer;
}
.message{
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  textarea{
    background: #262626;
    color: white;
    outline: none;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    font-family: Arial;
    resize: none;
    border: 1px solid #313131;
  }
}
.submit{
  cursor: pointer;
  display: flex;
  width: 200px;
  height: 60px;
  background: #D83634;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: ArmguardU;
  border: none;
  outline: none;
  margin: 10px auto 20px;
  &:disabled {
    background: #313131;
  }
}
.companyNameWrapper{
  margin-bottom: 30px;
}