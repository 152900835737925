.root{
  font-family: Arial;
  margin-bottom: 30px;
}
.readMore{
  color: #D83634;
  font-size: 16px;
  cursor: pointer;
  margin-top: 30px;
}
