@import "src/variables";

.root {
  background: #1A1A1A;
  min-height: 100vh;
  padding: 120px 100px 20px;
  color: white;

  :global {
    .swiper-pagination-bullet {
      width: 42px;
      height: 3px;
      border-radius: 0;
      background: #D9D9D9;
    }

    .swiper-pagination-bullet-active {
      background: #D83634;

    }
  }

  @media only screen and (max-width: $mediumDisplay) {
    padding: 100px 80px 40px;
  }
  @media only screen and (max-width: $smallDisplay) {
    padding: 60px 30px 40px;
  }
}

.zone {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
  @media only screen and (max-width: $mediumDisplay) {
    flex-direction: column;
  }
  @media only screen and (max-width: $smallDisplay) {
    margin-bottom: 20px;
  }
}

.image {
  width: 60%;
  margin-right: 70px;
  max-height: 700px;

  @media only screen and (max-width: $extraLargeDisplay) {
    width: 55%;
    margin-right: 50px;
  }

  @media only screen and (max-width: $mediumDisplay) {
    width: 100%;
    margin-right: 0;
    max-height: 300px;
  }

  @media only screen and (max-width: $smallDisplay) {
    height: auto;
    margin-bottom: 18px;
    margin-top: 18px;
  }

  svg {
    width: 100%;
    height: 100%;

    @media only screen and (max-width: $mediumDisplay) {
      max-height: 250px;
    }

  }
}

.info {
  width: calc(40% - 70px);

  @media only screen and (max-width: $extraLargeDisplay) {
    width: calc(45% - 50px);
  }

  @media only screen and (max-width: $mediumDisplay) {
    width: 100%;
    margin-bottom: 40px;
  }
}

.navigation {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
  justify-content: space-between;

  @media only screen and (max-width: $extraLargeDisplay) {
    svg {
      width: 50px!important;
    }
  }


  @media only screen and (max-width: $smallDisplay) {
    margin-bottom: 0;
  }
}

.name {
  font-size: 70px;
  margin: 0 40px;
  letter-spacing: 1px;
  @media only screen and (max-width: $extraLargeDisplay) {
    font-size: 55px;
  }
  @media only screen and (max-width: $largeDisplay) {
    font-size: 50px;
    margin: 0 10px;
  }
  @media only screen and (max-width: $mediumDisplay) {
    font-size: 54px;
  }
  @media only screen and (max-width: $smallDisplay) {
    font-size: 34px;
  }
  @media only screen and (max-width: $smallDisplay) {
    margin: 0 5px;
  }

  span {
    color: #D83634;
  }
}

.titleLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: $mediumDisplay) {
    svg {
      width: 40px;
      height: 40px;
    }
  }
}

.title {
  font-size: 48px;
  letter-spacing: 1px;
  @media only screen and (max-width: $mediumDisplay) {
    font-size: 38px;
  }
  @media only screen and (max-width: $smallDisplay) {
    font-size: 24px;
  }

  span {
    color: #D83634;
  }
}

.companies {
  font-family: Arial;
  max-height: 300px;
  font-size: 24px;
  overflow: auto;
  margin-top: 30px;
  line-height: 1.8;
  @media only screen and (max-width: $mediumDisplay) {
    font-size: 18px;
    max-height: 250px;
  }
  @media only screen and (max-width: $smallDisplay) {
    font-size: 12px;
    max-height: 155px;
  }
}

.button {
  margin-top: 50px;
}