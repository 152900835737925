.customCheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-bottom: 7px;
  padding-top: 7px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  color: white;
}

.customCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.customCheckbox .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-image: url('../../images/unchecked.png'); /* Path to your unchecked image */
  background-size: cover;
  border-radius: 4px;
}

.customCheckbox input:checked ~ .checkmark {
  background-image: url('../../images/checked.png'); /* Path to your checked image */
}
