@import "src/variables";

.root {
  position: fixed;
  color: white;
  padding: 30px 140px 20px;
  font-family: ArmguardU;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.3s ease, transform 0.3s ease-in-out; /* Combined transitions */

  @media only screen and (max-width: $largeDisplay) {
    padding: 15px 140px;
  }
  @media only screen and (max-width: $mediumDisplay) {
    padding: 0px 80px
  }
  @media only screen and (max-width: $smallDisplay) {
    padding: 0 30px
  }
  :global {


  }

}
.active {
  position: relative;

  @media only screen and (max-width: $largeLaptopDisplay) {
    color: #D83634;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -15px;
    right: 0;
    width: 65%;
    height: 3px;
    background: white;
    @media only screen and (max-width: $largeLaptopDisplay) {
      display: none;
    }
  }
}



.root.hide {
  transform: translateY(-150%); /* Hide header on scroll down */
}

.root.show {
  transform: translateY(0); /* Show header on scroll up */
}

.scrolled {
  background: #1A1A1A;
  box-shadow: 0px 20px 35px #242424;
}

.logo {
  width: 230px;
  cursor: pointer;
  @media only screen and (max-width: $largeDisplay) {
    width: 150px;
  }

  svg {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    right: 0;
    text-align: center;
    width: 150px;
  }
}

.nav {
  color: white;
  display: flex;
}

.menuItem {
  text-decoration: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  @media only screen and (max-width: $largeDisplay) {
    font-size: 16px;
  }
}

.item {
  display: flex;
}

.slash {
  margin: 0 20px;
}

.lang {
  color: white;
  padding-left: 40px;
  font-size: 18px;
  cursor: pointer;
  @media only screen and (max-width: $largeLaptopDisplay) {
    display: none;
  }
}
.activeLang{
  color: #D83634;
}

.mobileLangContainer {
  display: flex;
  gap: 32px;
  margin-bottom: 100px;
  & > p {
    font-size: 22px;
    cursor: pointer;
  }
}

.mobileNav {
  display: flex;
  align-items: center;

}

.mobileItems {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #1A1A1A;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobileItem {
  font-size: 48px;
  padding-bottom: 30px;

  a {
    color: white;
    text-decoration: none;
  }

  @media only screen and (max-width: $largeDisplay) {
    font-size: 24px;
    padding-bottom: 20px;
  }
}

.crossContainer {
  position: relative;
  width: 50px;
  height: 50px;
  z-index: 3;
}

.menuToggle {
  position: absolute;
  top: 49%;
  transform: translate(0, -50%);
  height: 26px;
  width: 50px;

  &, &:hover {
    color: #000;
  }
}

.menuToggleBar {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 0;
  width: 100%;
  height: 4px;
  background-color: white;
  transition: all 0.3s ease;

  &.menuToggleBarTop {
    transform: translate(0, -8px);
  }

  &.menuToggleBarMiddle {
    width: 80%;
    margin: 2px 0;
  }

  &.menuToggleBarBottom {
    transform: translate(0, 8px);
    width: 60%;
    margin: 4px 0;
  }

  .openedMenu & {
    &.menuToggleBarTop {
      transform: translate(0, 0) rotate(45deg);
    }

    &.menuToggleBarMiddle {
      opacity: 0;
      width: 100%;
      margin: 0;
    }

    &.menuToggleBarBottom {
      transform: translate(0, 0) rotate(-45deg);
      width: 100%;
      margin: 0;
    }
  }
}

.socials {
  margin-top: 30px;

  a {
    margin-right: 20px;
    margin-top: 20px;
    display: inline-block;
    @media only screen and (max-width: $largeDisplay) {
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  svg {
    width: 50px;
    height: 50px;
    @media only screen and (max-width: $largeDisplay) {
      width: 30px;
      height: 30px;
    }
  }
}