@import "src/variables";

.root {
  color: white;
  padding-top: 0;
  padding-bottom: 30px;
  background: linear-gradient(#262626, #111010);
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  &:after{
    content: "";
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: url("../../images/watermark.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 20px;
  }

  :global {
    .swiper {
      padding-top: 300px;
      padding-bottom: 40px;
      @media only screen and (max-width: $mediumDisplay) {
        padding-top: 120px;
        padding-bottom: 80px;
      }
      @media only screen and (max-width: $smallDisplay) {
        padding-top: 80px;
      }
    }
    .swiper-slide{
      height: 60vh;

      @media only screen and (max-width: $smallDisplay) {
        height: 40vh;
      }
    }
    .swiper-pagination-bullet {
      width: 42px;
      height: 3px;
      border-radius: 0;
      background: #D9D9D9;
    }

    .swiper-pagination {
      z-index: 6;
      position: absolute;
      bottom: 0px;
    }

    .swiper-pagination-bullet-active {
      background: #D83634;
    }

    .swiper-slide-prev, .swiper-slide-next {
      & > div {
        zoom: 0.7;
        width: 80%;
        margin: 10% auto 0;
      }
    }
  }

  @media only screen and (max-width: $mediumDisplay) {
    padding: 80px 0 80px;
    min-height: auto;
  }
  @media only screen and (max-width: $smallDisplay) {
    padding: 60px 0 60px;
    min-height: auto;
  }
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 140px 70px;
  position: absolute;
  top: 100px;
  width: 100%;
  @media only screen and (max-width: $mediumDisplay) {
    top: 0px;
    padding: 0 30px 70px;
  }
  @media only screen and (max-width: $smallDisplay) {
    top: 0px;
  }
  @media only screen and (max-width: $extraSmallDisplay) {
    display: none;
  }
}


.pageTitle {
  font-size: 80px;
  letter-spacing: 1px;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  text-align: center;

  span {
    color: #D83634;
  }

  @media only screen and (max-width: $mediumDisplay) {
    font-size: 54px;
    top: 85px;
  }
  @media only screen and (max-width: $smallDisplay) {
    font-size: 34px;
    top: 60px;
  }
}

.offer {
  text-align: center;
  margin-bottom: 80px;

  img {
    width: 100%;
  }
}

.title {
  font-size: 32px;
  margin-top: 30px;
  @media only screen and (max-width: $smallDisplay) {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.desc {
  font-size: 20px;
  font-family: Arial;
  @media only screen and (max-width: $smallDisplay) {
    font-size: 12px;
  }
}