.root{
  display: flex;
  flex-direction: column;
  color: white;
}

.input{
  height: 60px;
  background: #262626;
  color: white;
  outline: none;
  padding: 10px;
  font-size: 16px;
  font-family: Arial;
  border: 1px solid #313131;
  width: 100%;
}
.error{
  .input{
    border: 1px solid #D83634;
  }
}
.errorText{
  color: #D83634;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.label{
  margin-bottom: 10px;
  font-family: Arial;
}